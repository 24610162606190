@charset "utf-8";


$body-family: "Optima, Segoe, " Segoe UI ", Candara, Calibri, Arial, sans-serif;";

//@import url('https://fonts.googleapis.com/css?family=Nunito:400,700')
//$family-sans-serif: 'Nunito', sans-serif

$footer-background-color: #ffffff;

@import "../node_modules/bulma/bulma";
@import "../node_modules/bulma/sass/utilities/initial-variables";
@import "../node_modules/bulma/sass/layout/all";
@import "../node_modules/bulma/sass/elements/all";
@import "../node_modules/bulma/sass/base/all";
@import "../node_modules/bulma/sass/utilities/all";
@import "../node_modules/bulma/sass/components/navbar";
